import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,6,7,8,2,3];

export const dictionary = {
		"/": [13],
		"/apps": [~16,[4]],
		"/brands": [~17],
		"/bundles": [~18,[5]],
		"/bundles/listings": [19,[5,6]],
		"/bundles/listings/[bundleSlug=slug]": [~20,[5,6]],
		"/bundles/success": [~21,[5]],
		"/canceled": [22],
		"/checkout": [23],
		"/concepts": [~24],
		"/create": [~25],
		"/creator": [26,[7]],
		"/customerPortal": [27],
		"/devhub/[appId=integer]": [28,[8]],
		"/docs": [29,[9]],
		"/explore": [~30],
		"/favorites": [~31],
		"/login": [32],
		"/metadata": [~33,[10]],
		"/my-apps": [~34],
		"/other-payment": [35],
		"/payments/cancel": [36],
		"/payments/fail": [37],
		"/payments/success": [38],
		"/privacy-policy": [39,[11]],
		"/signout": [40],
		"/success": [41],
		"/visuals": [42,[12]],
		"/[appId=integer]": [14,[2]],
		"/[appId=integer]/edit": [15,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';